<template>
  <form class="form" @submit.stop.prevent="onSubmit">
    <div class="form-group">
      <label for="">Send SMS</label>
      <span class="switch">
        <input
          type="checkbox"
          v-model="send_sms"
          class="switch"
          id="send_sms_switch"
        />
        <label for="send_sms_switch"></label>
      </span>
    </div>
    <div class="form-group">
      <label for=""
        >Twilio Account SID <span class="text-danger">*</span></label
      >
      <select class="form-control" id="" v-model="sms_provider">
        <option value="twilio">Twilio</option>
      </select>
    </div>

    <div class="form-group">
      <label for="">Twilio Account SID<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="account_sid"
        :class="errors['account_sid'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['account_sid']" class="text-danger">{{
        errors["account_sid"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">Twilio Auth Token <span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="auth_token"
        :class="errors['auth_token'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['auth_token']" class="text-danger">{{
        errors["auth_token"][0]
      }}</span>
    </div>

    <div class="form-group">
      <label for="">Twilio From Number<span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="from_number"
        :class="errors['from_number'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['from_number']" class="text-danger">{{
        errors["from_number"][0]
      }}</span>
    </div>

    <hr />
    <!-- 
    <h4 @click="checkTwilioKey()">Checkme</h4> -->
    <button
      class="btn btn-success"
      ref="kt_sms_config_submit"
      style="float:right"
    >
      Submit
    </button>
  </form>
</template>

<script>
import {
  FETCH_SMS_CONFIG,
  SUBMIT_SMS_CONFIG
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      account_sid: "",
      sms_provider: "twilio",
      auth_token: "",
      from_number: "",
      send_sms: false,
      errors: [],
      id: 0
    };
  },
  created() {
    this.$store.dispatch(FETCH_SMS_CONFIG).then(data => {
      var data_ = data.data;
      this.account_sid = data_.account_sid ? data_.account_sid : "";
      this.sms_provider = data_.sms_provider ? data_.sms_provider : "twilio";
      this.auth_token = data_.auth_token ? data_.auth_token : "";
      this.from_number = data_.from_number ? data_.from_number : "";
      this.send_sms = data_.send_sms ? data_.send_sms : 0;
      this.id = data_.id ? data_.id : 0;
    });
  },

  methods: {
    // checkTwilioKey() {
    //   const client = require("twilio")(this.account_sid, this.auth_token);
    //   client.newKeys.create({friendlyName: 'User Joey'})
    //           .then(new_key => console.log(new_key.sid));
    // },
    onSubmit() {
      this.errors = [];
      const submitButton = this.$refs["kt_sms_config_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var data = {
        account_sid: this.account_sid,
        sms_provider: this.sms_provider,
        auth_token: this.auth_token,
        from_number: this.from_number,
        send_sms: this.send_sms,
        id_: this.id
      };

      this.$store
        .dispatch(SUBMIT_SMS_CONFIG, data)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(data.msg);
        })
        .catch(err => {
          this.errors = err.msg;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          // this.$toastr.e(err.msg);
        });
    }
  }
};
</script>
