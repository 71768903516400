<template>
  <form class="form" @submit.stop.prevent="onSubmit">
    <div class="form-group">
      <label for="">Browser Map Key <span class="text-danger">*</span></label>
      <input
        type="text"
        name=""
        class="form-control"
        id=""
        v-model="browser_map_key"
        :class="errors['browser_map_key'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['browser_map_key']" class="text-danger">{{
        errors["browser_map_key"][0]
      }}</span>
    </div>
    <div class="form-group">
      <label for="">Map Search Radius <span class="text-danger">*</span></label>

      <div class="input-group mb-3">
        <input
          v-model="map_search_radius"
          :class="errors['map_search_radius'] ? 'border border-danger' : ''"
          type="text"
          class="form-control"
          aria-describedby="basic-addon2"
        />
        <span class="input-group-text" id="basic-addon2">Miles</span>
      </div>
      <span v-if="errors['map_search_radius']" class="text-danger">{{
        errors["map_search_radius"][0]
      }}</span>
    </div>

    <hr />

    <button
      class="btn btn-success"
      ref="kt_map_config_submit"
      style="float:right"
    >
      Submit
    </button>
  </form>
</template>

<script>
import {
  FETCH_MAP_CONFIG,
  SUBMIT_MAP_CONFIG
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      browser_map_key: "",
      map_search_radius: 50,
      errors: [],
      id: 0,
      browser_map_key_valid: false
    };
  },
  created() {
    this.$store.dispatch(FETCH_MAP_CONFIG).then(data => {
      var data_ = data.data;
      this.browser_map_key = data_.browser_map_key ? data_.browser_map_key : "";
      this.map_search_radius = data_.map_search_radius
        ? data_.map_search_radius
        : "";
      this.id = data_.id ? data_.id : 0;
    });
  },

  methods: {
    onSubmit() {
      var request = new XMLHttpRequest();
      this.browser_map_key_valid = false;

      var method = "GET";
      var url =
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=27,80&key=" +
        this.browser_map_key;
      var async = true;

      request.open(method, url, async);
      this.errors = [];
      const submitButton = this.$refs["kt_map_config_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      // var j = 0;
      // var address = "";
      var that = this;
      request.onreadystatechange = function() {
        if (request.readyState == 4 && request.status == 200) {
          var da = JSON.parse(request.responseText);

          if (da.status == "OK") {
            that.$store
              .dispatch(SUBMIT_MAP_CONFIG, {
                browser_map_key: that.browser_map_key,
                map_search_radius: that.map_search_radius,
                id_: that.id
              })
              .then(data => {
                if (that.id == 0) {
                  that.id = data.id;
                }
                submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
                submitButton.disabled = false;
                that.$toastr.s(data.msg);
              })
              .catch(err => {
                if (err.status) {
                  that.errors = err.data.msg;
                } else {
                  that.$toastr.e(err.data.msg);
                }

                submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
                submitButton.disabled = false;
              });
            //  return true;
          } else {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            that.$toastr.e(da.error_message);
          }
        }
      };
      request.send();
      // return false;
      //  that.fetchFClients();
    }
  }
};
</script>
