<template>
  <div class="row">
    <div class="col-md-6 offset-3">
      <div class="card form-body">
        <div class="card-body">
          <nav
            class="nav nav-pills nav-justified"
            v-if="currentUser.u.is_admin == 0"
          >
            <a
              class="nav-link"
              @click="active_tab = 'map'"
              :class="active_tab == 'map' ? 'active' : ''"
              aria-current="page"
              href="#"
            >
              <i
                class="fa fa-map mr-2"
                :class="active_tab == 'map' ? 'text-white' : ''"
              ></i>
              Map</a
            >
            <a
              class="nav-link"
              @click="active_tab = 'sms'"
              :class="active_tab == 'sms' ? 'active' : ''"
              href="#"
            >
              <i
                class="fa fa-comment mr-2"
                :class="active_tab == 'sms' ? 'text-white' : ''"
              ></i>
              SMS</a
            >
          </nav>
          <hr v-if="currentUser.u.is_admin == 0" />

          <v-map v-if="active_tab == 'map'"></v-map>
          <v-sms v-if="active_tab == 'sms'"></v-sms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vMap from "@/pages/backend/settings/map_n_sms_config/map";
import vSms from "@/pages/backend/settings/map_n_sms_config/sms";
export default {
  components: {
    vMap,
    vSms
  },
  data() {
    return {
      active_tab: "map",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: ""
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Map & SMS Config",
          route: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    if (this.$route.name == "client.map_config") {
      this.breadCrumbs[0].route = "admin.dashboard";
      this.breadCrumbs[2].title = "Map Config";
      this.active_tab = "map";
    } else {
      this.breadCrumbs[0].route = "client.dashboard";
      this.breadCrumbs[2].title = "Map & SMS Config";
    }

    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  }
};
</script>
